<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userData.action }} Tag Setting
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Tag Name -->
          <validation-provider
            #default="validationContext"
            name="Tag Name"
            rules="required"
          >
            <b-form-group
              label="Tag Name"
              label-for="tag-name"
            >
              <b-form-input
                v-if="userData.action === 'Add'"
                id="tag-name"
                v-model="userData.tag_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
              <b-form-input
                v-else
                id="tag-name"
                v-model="userData.tag_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Member Tambah -->
          <validation-provider
            #default="validationContext"
            name="Member Tambah"
            rules="required"
          >
            <b-form-group
              label="Member Tambah"
              label-for="tambah"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.tambah"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="tambah"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Member Kurang -->
          <b-form-group
            label="Member Kurang"
            label-for="kurang"
          >
            <v-select
              v-model="userData.kurang"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="kurang"
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Target (kWh/kg)"
            rules="required"
          >
            <b-form-group
              label="Default"
              label-for="default"
              :state="getValidationState(validationContext)"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="userData.defaultt"
                  name="some-radios"
                  value="1"
                >
                  Yes
                </b-form-radio>
                <b-form-radio
                  v-model="userData.defaultt"
                  name="some-radios"
                  value="0"
                >
                  No
                </b-form-radio>
              </div>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Target (kWh/kg)"
            rules="required||positive"
            type="number"
          >
            <b-form-group
              label="Target (kWh/kg)"
              label-for="target"
            >
              <b-form-input
                id="target"
                v-model="userData.target"
                type="number"
                step="0.001"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-add-new-user-sidebar-active', false),$emit('refetch-data')"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, ref, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  methods: {
    resetuserData({ emit }) {
      emit('refetch-data')
      emit('update:is-add-new-user-sidebar-active', false)
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const groupOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/device-list/view`)
      const DataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: `${DataAPI[i].showname}`, value: DataAPI[i].id_dev })
      }
      groupOptions.value = combo
    })

    // console.log(groupOptions)

    const { userData } = toRefs(props)

    const onSubmit = () => {
      store.dispatch('app-user/crudData', userData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          if (response.data.message === 'Data Saved' || response.data.message === 'Data Updated') {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'success',
              },
            })
            emit('refresh-detail')
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: 'Error Saving Data / Duplicate Data',
                variant: 'danger',
              },
            })
            // console.log()
            emit('refresh-detail')
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      userData,
      groupOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
